import styled from "styled-components"

const Wrapper = styled.h2`
  text-align: center;
  margin: 72px auto;
  max-width: 900px;
  font-size: 24px;
  font-weight: 500;

  @media (${props => props.theme.mediaQueries.tablet}) {
    margin: 120px auto;
    font-size: 36px;
  }
`

export default Wrapper
