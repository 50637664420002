import React from "react"

//React components imports
import Layout from "../components/Layout"
import ConfirmationMessage from "../components/ConfirmationMessage"
import PageTitle from "../components/PageTitle"

import ReservationText from "../components/ReservationText"

const ReservationConfirmationPage = props => {
  return (
    <Layout>
      {/* <PageTitle>Rezervácia</PageTitle>
      <ConfirmationMessage /> */}
      <ReservationText />
    </Layout>
  )
}

export default ReservationConfirmationPage
